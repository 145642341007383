<template>
  <transition name="mobysuite-aftersale-client-modal-fade">
    <div class="mobysuite-aftersale-client-modal-backdrop">
      <div
        class="mobysuite-aftersale-client-modal"
        role="dialog"
        id="mobysuite-aftersale-client-modal-automatic-scheduling"
        style="overflow: visible;"
      >
        <header 
					class="mobysuite-aftersale-client-modal-header"
					id="mobysuite-aftersale-client-modalTitle"
          v-if="scheduleTitle=='reagendamiento'"
				>
					<button
						type="button"
						class="mobysuite-aftersale-client-btn-close"
						@click="close"
						aria-label="Close mobysuite-aftersale-client-modal"
					>
						x
					</button>
				</header>
        <section
          class="mobysuite-aftersale-client-modal-body"
          id="mobysuite-aftersale-client-modalDescription"
        >
          <slot name="body">
            <b-overlay
              id="overlay-background"
              :show="getSavingState"
              :variant="variant"
              :opacity="opacity"
              :blur="blur"
              rounded="sm"
            >
              <b-row class="my-2">
                <b-col cols="12" class="title my-2">
                  Elegir fecha para el {{scheduleTitle}}
                </b-col>
                <b-col cols="12">
                  <b-form-datepicker
                    v-model="date"
                    :date-disabled-fn="dateDisabled"
                    locale="es"
                    class="mb-2 date-picker-custom"
                    data-date-container='#mobysuite-aftersale-client-modal-automatic-scheduling'
                    boundary="mobysuite-aftersale-client-modal-automatic-scheduling"
                    label-calendar="Calendario"
                    label-close-button="Cerrar"
                    label-current-month="Mes actual"
                    label-help="Use las teclas del cursor para navegar por las fechas del calendario"
                    label-nav="Navegación del calendario"
                    label-next-month="Siguiente mes"
                    label-next-year="Siguiente año"
                    label-no-date-selected="Sin fecha seleccionada"
                    label-prev-month="Mes anterior"
                    label-prev-year="Año anterior"
                    label-reset-button="Reiniciar"
                    label-today="Día de hoy"
                    label-today-button="Seleccionar el día de hoy"
                    :min="today"
                  >
                  </b-form-datepicker>
                  <div v-if="showErrorMessage" style="color: red">
                    <p>{{errorMessage}}</p>
                  </div>
                </b-col>
                <b-col sm="12" class="mt-2" v-if="!(date === null || date=='')">
                  <p class="title my-1">
                    Elegir una hora de la jornada para ver disponibilidad
                  </p>
                  <b-form-select
                    v-model="hour"
                    :options="getAvailableHours.hours"
                    class="date-picker-automatic"
                    v-if="getAvailableHours.hours"
                  >
                  </b-form-select>
                  <h4 style="color: red;" v-else>
                    No hay horas disponibles para este día
                  </h4>
                  <div v-if="disabledButton() && hour" style="color: red">
                    <p>No hay inspectores disponibles para esta hora.</p>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
            <success-view
              v-if="getShowSuccessView"
              title="Tu hora ha sido solicitada con éxito!"
              buttonMessage="Volver"
              :message="
                'Tu hora ha sido solicitada con éxito para ' +
                formatDateAssigned(getSelectedDate) +
                ' a las ' +
                getSelectedHour
              "
              :modalShow="getShowSuccessView"
              successType="automatic"
            ></success-view>
          </slot>
        </section>

        <footer class="mobysuite-aftersale-client-modal-footer">
          <slot name="footer"> </slot>
          <b-row class="justify-content-md-center text-center">
            <b-col lg="12" sm="12" class="text-center">
              <button
                type="button"
                :class="
                  disabledButton() ? 'moby-btn-primary-disabled ' : 'btn-primary'
                "
                @click="schedule()"
                aria-label="Close mobysuite-aftersale-client-modal"
                :disabled="disabledButton()"
              >
                Agendar
              </button>
            </b-col>
          </b-row>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SuccessView from '@/components/SuccessView.vue'

export default {
  name: "AutomaticScheduling",
  data() {
    return {
      hour: null,
      date: null,
      showErrorMessage: false,
      errorMessage: null,
      today: null,
      hollidays: [],
      variant: 'light',
      opacity: 0.85,
      blur: '2px',
    };
  },
  components: {
    SuccessView,
  },
  props: {
    scheduleTitle: { type: String, default: null },
    type: { type: String, default: null },
  },
  computed: {
    ...mapGetters("assets", ["getSelectedAsset", "getSelectedProject"]),
    ...mapGetters("general", [
      "getHasAutomaticScheduling",
      "getRequestId",
      "getDaysAndHours",
      "getDaysAndHoursWithIds",
      "getShowSuccessView",
      "getSavingState",
      "getAvailableHours",
      "getHollidaysChile",
      "getautomaticSchedulingCode",
      'getCustomerId',
    ]),
    ...mapGetters("elements", [
      "getSelectedHour",
      "getSelectedDate",
    ])
  },
  watch: {
    date(){
      this.setAvailableHours({
        success: true,
        available_hours: 0,
        error_message: null,
      })
      if (this.date != null)
      {
        this.getHours() 
      }      
      let validate = new Date (this.date).getDay()
      if (validate == 5 || validate == 6 || isNaN(validate) || this.hollidays.includes(this.date)) {
        this.date = ''
        this.hour = null
        this.showErrorMessage = true
        this.errorMessage = "Para esta fecha no hay horarios disponibles."
      }
      else
        this.hour = null
        this.showErrorMessage = false
    },
    hour(){
    },
  },
  created() {
    var today = new Date()
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    } 
    today = yyyy + '-' + mm + '-' + dd;
    this.today = today
    this.hour = null
    this.date = null
    this.getHollidaysOfChile()
    this.setAvailableHours({
      success: true,
      available_hours: 0,
      error_message: null,
    })
  },
  methods: {
    ...mapMutations("elements", [
      "setSelectedPlace",
      "setSelectedItem",
      "setSelectedDetail",
      "setSelectedProblem",
      "setAddedElements",
      "setAddedRequirements",
      "setSelectedHour",
      "setSelectedDate",
    ]),
    ...mapMutations("general", [
      "setShowSuccessView",
      "setMenuOption",
      "setShowAutomaticScheduling",
      "setAvailableHours",
    ]),
    ...mapActions("assets", ["getRequestsByAsset"]),
    ...mapActions("general", ["automaticScheduling", "getAvailableHoursPerDay"]),
    cleanObjects() {
      this.setSelectedPlace(null)
      this.setSelectedItem(null)
      this.setSelectedDetail(null)
      this.setSelectedProblem(null)
      this.setAddedElements([])
      this.setAddedRequirements([])
      this.getRequestsByAsset({
        asset: this.getSelectedAsset.asset_number,
        project: this.getSelectedProject.project_id,
      })
    },
    schedule() {
      let hour = this.convertTime12To24(this.hour.substring(0,2) + ":00" + this.hour.substring(2, this.hour.length)) + ":00"
      let starting_date = this.date+" "+hour
      this.cleanObjects()
      this.automaticScheduling({
        request_id: this.getRequestId,
        starting_date: starting_date,
        date: this.date,
        type: this.type,
      })
      this.setSelectedHour(hour)
      this.setSelectedDate(this.date)
      this.hour = null
    },
    disabledButton() {
      if (this.getAvailableHours){
        if (this.getAvailableHours.hours)
        {
          return false
        }
        else
        {
          return true
        }
      }
      else
      {
        return true
      }
    },
    close() {
      this.$emit("close")
      this.setShowAutomaticScheduling(false)
    },
    convertTime12To24(time) {
      var hours   = Number(time.match(/^(\d+)/)[1])
      var minutes = Number(time.match(/:(\d+)/)[1])
      var AMPM    = time.match(/\s(.*)$/)[1]
      if (AMPM === "PM" && hours < 12) hours = hours + 12
      if (AMPM === "AM" && hours === 12) hours = hours - 12
      var sHours = hours.toString()
      var sMinutes = minutes.toString()
      if (hours < 10) sHours = "0" + sHours
      if (minutes < 10) sMinutes = "0" + sMinutes
      return (sHours + ":" + sMinutes)
    },
    getHollidaysOfChile(){
      let data = this.getHollidaysChile
      data.forEach(
        element =>
        this.hollidays.push(element["fecha"])
      )
    },
    getHours() {
      this.getAvailableHoursPerDay({
        code: this.getautomaticSchedulingCode,
        date: this.date,
        request_id: this.getRequestId,
      })
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay()
      return weekday === 0 || weekday === 6 || this.hollidays.includes(date.toISOString().split('T')[0])
    },
    formatDateAssigned(value) {
      let date = new Date(value);
      let day = String(date.getUTCDate()).padStart(2, '0');
      let month = String(date.getUTCMonth() + 1).padStart(2, '0');
      let year = date.getUTCFullYear();
      return `${day}-${month}-${year}`;
    },
  },
};
</script>

<style>
.success-view-icon-style {
  transform: scale(8);
  margin: 45px;
  color: #00718c;
}
.title {
  font-size: 18px;
  margin-bottom: 0px;
}

.btn-primary .active {
  min-width: 32px !important;
  width: 32px !important;
}

.dropdown-menu{
  position: absolute !important;
  z-index: 9999;
}

.b-calendar .b-calendar-grid-body .col[data-date] .btn{
  width: 32px !important;
  height: 32px !important;
  font-size: 14px !important;
  line-height: 1 !important;
  margin: 3px auto !important;
  padding: 9px 0 !important;
  min-width: 0px !important;
}
</style>