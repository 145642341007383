<template>
  <div>
    <b-row
      align-v="center"
      class="justify-content-md-center bg-white py-5 login-container text-center"
    >
      <b-col
        md="6"
        sm="12"
        v-if="getLogInImage == '' && !isMobile()"
      >
        <logo></logo>
        {{ getLogInImage }}
      </b-col>
      <b-col
        md="6"
        sm="12"
        v-if="getLogInImage"
      >
        <img :src="getLogInImage" class="mobysuite-aftersale-client-project-image" style="margin: auto !important;">
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <b-alert variant="danger" :show="error.length > 0" dismissible style="max-width: 600px;">{{error}}</b-alert>
        <h1 class="login-text-form">¡Bienvenido a nuestro sistema de Postventa!</h1>
        <h2 style="font-size: 20px;" class="login-text-form">Por favor, inicia sesión</h2>
        <b-form @submit="login" >
          <b-form-group
            id="rut-login"
            label="Rut"
            label-for="rut-login-input"
            class="text-left"
          >
            <b-form-input
              id="rut-login-input"
              @keyup="formatRut"
              v-model="rut"
              type="text"
              placeholder="Ingresa tu rut: "
              autocomplete="on"
              required
              class="border-dark login-input-form"
              maxlength="10"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="password-login"
            label="Contraseña:"
            label-for="password-login-input"
            class="text-left"
          >
            <b-form-input
              id="password-login-input"
              v-model="password"
              type="password"
              placeholder="Ingresa tu contraseña"
              autocomplete="on"
              required
              class="border-dark login-input-form"
            ></b-form-input>
          </b-form-group>
          <b-row>
            <b-col
              :sm="getEnableLessee ? 6 : 12"
              :class="getEnableLessee ? 'text-left' : 'text-right'"
            >
              <p class="recover-password-text" @click="showForgetPasswordModal">¿Olvidaste tu contraseña?</p>
            </b-col>
            <b-col
              sm="6"
              class="text-right"
              v-if="getEnableLessee"
            >
              <p class="recover-password-text" @click="showLesseeWidgetModal">Soy arrendatario</p>
            </b-col>
          </b-row>
          <div class="login-button-submit">
          <b-button type="submit" variant="moby" class="my-2" block  @click.prevent="login">Iniciar sesión</b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <recover-password
      v-show="getShowRecoverPassword"
      @close="closeModal"
    >
    </recover-password>
    <lessee-widget
      v-show="getShowLesseeWidget"
      @close="closeModal"
      v-if="getEnableLessee"
    >
    </lessee-widget>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapMutations, } from 'vuex'
import Logo from '@/components/Logo.vue'
import RecoverPassword from '@/components/RecoverPassword.vue'
import LesseeWidget from '@/components/LesseeWidget.vue'
  export default {
	name: 'Login',
    data() {
      return {
        rut: '',
        password: '',
        error: '',
      }
    },
    components: {
      Logo,
      RecoverPassword,
      LesseeWidget,
    },
    computed: {
      ...mapGetters('general', [
        'getCustomer',
        'getCustomerId',
        'getContractId',
        'getEnableLessee',
        'getShowRecoverPassword',
        'getShowLesseeWidget',
        'getLogInImage',
      ]),
    },
    methods: {
      ...mapActions('assets', ['fetchData']),
      ...mapMutations('general', [
        'setShowRecoverPassword',
        'setShowLesseeWidget',
      ]),
      login(){
        this.$auth.login({
          data: { email: this.rut, password: this.password, customer_id: this.getCustomerId},
					success: function(msg) {
						this.error = "";
						this.$auth.user(msg.data.user);
            this.fetchData({contractId: this.getContractId})
					},
          fetchUser: true,
        }).then(null, () => {
          this.error = this.getCustomerId == 64 ? "Si su RUT no es reconocido, intente con el RUT del otro titular, si lo hubiera, o con el RUT de la persona jurídica en caso de que la propiedad esté inscrita de esa manera" : "Usuario y/o contraseña incorrecto(s)"

        })
      },
      digito(rut, dv) {
        let suma = 0;
        let multiplicador = 2;
        while (rut !== 0) {
          if (multiplicador === 8) {
            multiplicador = 2;
          }
          suma += (rut % 10) * multiplicador;
          rut = Math.trunc(rut / 10);
          multiplicador++;
        }
        suma = 11 - (suma % 11);
        switch (suma) {
          case 11:
            return ("0" === dv);
          case 10:
            return ("K" === dv.toUpperCase());
          default:
            return (suma.toString() === dv);
        }
      },
      formatRut(){
        if(this.rut.length > 0){
          this.rut = this.rut.replace(/[^\dKk]/g, '');
          let dato = this.rut;
          let largo = dato.length;
          if (largo === 1) {
            this.dv = dato;
            this.rut = '';
            this.digito(this.rut, this.dv);
            this.formato()
          } else if (largo > 1) {
            this.dv = dato.substring(largo - 1);
            this.rut = dato.substring(largo - 1, 0);
            this.digito(this.rut, this.dv);
            this.formato()
          }
        }
      },
      formato() {
        let rut = (this.rut) ? (parseInt(this.rut)) + '-' : '';
        this.rut = rut + this.dv;
      },
      closeModal() {
        this.setShowRecoverPassword(false)
      },
      showForgetPasswordModal(){
        this.setShowRecoverPassword(true)
      },
      showLesseeWidgetModal(){
        this.setShowLesseeWidget(true)
      },
      isMobile(){
        if (/Mobi|Android/i.test(navigator.userAgent)) {
          return true
        }
        else{
          return false
        }
      },
    },
  }
</script>


<style scoped>
  .mobysuite-aftersale-client-project-image{
    max-width: 70%;
    height: auto;
  }
  @media only screen and (max-width: 599px) {
    .mobysuite-aftersale-client-project-image{
      max-width: 100%;
      height: 80%;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    .mobysuite-aftersale-client-project-image{
      max-width: 55%;
      height: 55%;
    }
  }
</style>
